<template>
    <div class="wrapper">
        <div class="position-relative w-100 vh-75 mb-5">
            <div class="img-fixed h-100 w-100" v-if="boda.portada && boda.portada.length>0">
                <div class="position-absolute" style="top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(0, 0, 0, 0.5); z-index: 1;"></div>
                <img :src="boda.portada[0].ARCHIVO" alt="Portada" class="img-background h-100 w-100">
            </div>
            <div class="h-100 w-100" style="background-color: #6c757d;" v-else></div>
            <div class="position-absolute top-0 bottom-0 start-0 end-0" style="z-index: 2">
                <div class="d-flex justify-content-center align-items-center text-white h-100">
                    <div class="text-center">
                        <h2 v-if="boda.FECHA_BODA" class="mb-0">{{$filters.date(boda.FECHA_BODA)}}</h2>
                        <h2 v-else class="mb-0">Sin fecha</h2>
                        <h1 class="playfairDisplay display-1">{{nombre1}} & {{nombre2}}</h1>
                    </div>
                </div>
                <div class="position-absolute start-0 end-0" style="bottom: -86px;">
                    <div class="container">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-md-4">
                                <span style="background-color: rgba(0,0,0,.5);" class="d-inline-block rounded" v-if="user.CODIGO_BODA == boda.CODIGO_BODA">
                                    <router-link :to="{name: 'boda.index'}" class="btn btn-outline-light">
                                        <font-awesome-icon :icon="faEdit" />
                                        Editar boda
                                    </router-link>
                                </span>
                            </div>
                            <div class="col-md-4 text-center">
                                <div v-if="boda.perfil && boda.perfil.length>0">
                                    <img :src="boda.perfil[0].ARCHIVO" :alt="boda.NOMBRE_PAREJA_1+' y '+boda.NOMBRE_PAREJA_2" width="250" height="250" class="rounded-circle">
                                </div>
                                <div v-else>
                                    <img :src="ImgEmpty" :alt="boda.NOMBRE_PAREJA_1+' y '+boda.NOMBRE_PAREJA_2" width="250" height="250" class="rounded-circle">
                                </div>
                            </div>
                            <div class="col-md-4 text-end">
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="pt-5">
            <h3 v-text="boda.MENSAJE_INVITADOS" class="text-center playfairDisplay"></h3>
        </div>
        
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 mb-3">
                    <div v-if="items.length>0" class="text-end">
                        <router-link :to="{name: 'home'}" class="btn btn-outline-primary mb-3">
                            <span class="ei-icon_bag_alt" style="font-size: 20px;"></span>
                            Ir al carrito de compras
                        </router-link>
                    </div>

                     <div class="text-center text-muted" v-if="loadingMesas">
                        Cargando...
                    </div>
                    <div v-else>
                        <div v-if="mesasFiltered.length>0" class="row">
                            <div v-for="(mesa, index) in mesasFiltered" :key="index" class="col-lg-4 mb-3">
                                <router-link :to="{name: 'productos.mesas.show', params: {id: mesa.producto.SLUG, slug: $route.params.id}}" v-if="mesa.producto">
                                    <CardProducto :data="mesa.producto" :id="mesa.CODIGO_BODA_MESA" :mesa="mesa" type="compra" />
                                </router-link>
                            </div>
                        </div>
                        <div class="card bg-light border-0 shadow" v-else>
                            <div class="card-body">
                                <div class="text-center text-muted">
                                    No hay productos en la mesa
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import CardProducto from '@/components/productos/CardProducto.vue'
import ImgEmpty from '@/assets/img/empty-image.png'
export default {
    components: {
        FontAwesomeIcon,
        CardProducto
    },
    data(){
        return {
            faEdit,
            loadingMesas: false,
            ImgEmpty
        }
    },
    mounted(){
        this.getData()
        /*if(!this.boda.CODIGO_BODA){
        }*/
    },
    methods: {
        getData(){
            if(!this.codigo) {return}
            this.loadingMesas = true
            this.$store.dispatch('getBodas', {
                SLUG: this.codigo
            }).then((res) => {
                return res
            }).catch(err => {
                this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingMesas = false
            })
        },
    },
    computed: {
        codigo(){
            return this.$route.params.id || null
        },
        bodas() {
            var data = this.$store.getters.bodas || {}
            return data.data || []
        },
        items() {
            return this.$store.getters.items || []
        },
        user() {
            return this.$store.getters.currentUser || {}
        },
        boda() {
            return this.bodas.filter(x => x.SLUG == this.codigo)[0] || {}
        },
        mesasFiltered(){
            return (this.boda.mesas || []).filter(x => x.ESTATUS == '0')
        },
        nombre1(){
            var data = this.boda.NOMBRE_PAREJA_1 || ''
            return data.split(' ')[0]
        },
        nombre2(){
            var data = this.boda.NOMBRE_PAREJA_2 || ''
            return data.split(' ')[0]
        }
    }
}
</script>